<template>
  <div>
       <div style="margin-bottom: 20px">
      <el-row>
        <el-col :span="24" align="left">
          <el-button type="primary" @click="goBack">返回</el-button>
        </el-col>
      </el-row>
    </div>
        <el-form
        ref="perForm"
        :model="formData"
        :rules="rules"
        label-width="200px"
      >
      <el-form-item label="logo" prop="logoId">
          <el-upload
            class="avatar-uploader"
            action="http://up-z2.qiniup.com"
            :show-file-list="false"
            accept="image/jpeg,image/gif,image/png"
            :on-success="handleAvatarSuccess"
            :data="postData"
            :before-upload="beforeAvatarUpload"
          >
            <div v-if="imageUrl" class="avatar">
              <img :src="imageUrl" width="50%" />
            </div>
           
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="标题名称" prop="title">
          <el-input
            v-model="formData.title"
            placeholder="请输入标题名称"
            style="width: 50%"
          />
        </el-form-item>
      
       <el-form-item label="分类类型" prop="typeId">
          <el-select
            v-model="formData.typeId"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.typeName"
              :value="item.typeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布人名称" prop="publishUserName">
          <el-input
            v-model="formData.publishUserName"
            placeholder="请输入发布人名称"
            style="width: 50%"
          />
        </el-form-item>
<el-form-item label="来源类型" prop="sourceType" v-if="!formData.articleId">
       <el-radio v-model="formData.sourceType" :label='1' @change="change">本地</el-radio>
       <el-radio v-model="formData.sourceType" :label="2" @change="change">外部链接</el-radio>
    
      </el-form-item>
      <el-form-item label="资讯详情" prop="content"  v-if="formData.sourceType==1">
          <div style="line-height: normal">
            <uEditor v-model="formData.content"></uEditor>
          </div>
        </el-form-item>
         <el-form-item label="外部链接" prop="urlMsg" v-if="formData.sourceType==2">
          <el-input v-model="formData.urlMsg" style="width: 30%" />
        </el-form-item>
        <el-form-item label="排序" prop="sort" v-if="formData.articleId">
          <el-input v-model="formData.sort" style="width: 30%" />
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
  </div>
</template>

<script>
import {
  addType,
  getUploadToken,
  getTypes,
  getArticles,
  addArticle,
  setArticleStatus,
  modifyArticle,
  delArticle,
} from "../../request/http";
export default {
  data() {
    return {
   
      editOradd: "",
      imageUrl: "",
      postData: {
        token: "",
        key: "", //上传的Logo
      },
       options: [],
       formData: {
        logoId: "",
        typeId: "",
        publishUserName: "",
        sourceType: null,
        title: "",
        content: "",
        urlMsg: "",
      },
  rules: {
        title: [
          { required: true, message: "标题名称不能为空", trigger: "blur" },
        ],
        logoId: [{ required: true, message: "logo不能为空", trigger: "blur" }],
        publishUserName: [
          { required: true, message: "发布人名称不能为空", trigger: "blur" },
        ],
        sourceType: [
          { required: true, message: "来源类型不能为空", trigger: "blur" },
        ],
        content: [
          { required: true, message: "资讯详情不能为空", trigger: "blur" },
        ],
        urlMsg: [
          { required: true, message: "外部链接不能为空", trigger: "blur" },
        ],
        typeId: [
          { required: true, message: "分类Id不能为空", trigger: "blur" },
        ],
        sort: [{ required: true, message: "排序不能为空", trigger: "blur" }],
      },
      

    };
  },
  created() {
        this.getList()

    console.log(this.$route.query);
    //editOradd 传过来的为 0 或者 1
    this.editOradd = this.$route.query.editOradd == 0 ? false : true;
    if (this.$route.query.obj) {
      let val = this.$route.query.obj;
        this.imageUrl = "http://img.c-fol.cn/" + val.logoId;
      this.formData = {
        articleId: val.articleId,
        logoId: val.logoId,
        typeId: val.typeId,
        publishUserName: val.publishUserName,
        sourceType: val.sourceType,
        title: val.title,
        content: val.content,
        urlMsg: val.urlMsg,
        sort: val.sort,
      }
    }
  },
  mounted() {},

  methods: {
  async getList() {
      let res = await getTypes({
        typeGroup: "ARTICLE",
      });
      console.log(res);

      this.options = res.data;
    },
 selectChange(value) {
      this.formData.typeId = value;
      console.log(this.formData.typeId);
    },
    async change(val) {
      console.log(val);
      this.formData.sourceType = Number(val);
    },
    // 返回上一级页面
    goBack() {
      this.$router.go(-1);
    },
    // 确认
    async btnOK() {
      this.$refs.perForm.validate().then(async () => {
          
        if (this.formData.articleId) {
 
          let { code, msg } = await modifyArticle(this.formData);
          if (this.formData.articleId && code == 0) {
            this.$message.success("编辑成功");
          } else {
            console.log(msg);
            this.$message({
              type: "error",
              message: msg,
            });
          }
          this.goBack();
        } else {
        
          let { code, msg } = await addArticle(this.formData);
          console.log(code, msg);
          if (code == 0) {
            this.$message.success("新增成功");
          } else {
            console.log(msg);
            this.$message({
              type: "error",
              message: msg,
            });
          }
          this.goBack();
        }
      });
   
    },

    //弹窗取消函数
    btnCancel() {
      this.goBack();
    },
  

    // 上传图片方法
    handleAvatarSuccess(res, file) {
      console.log("上传成功", res, file);
      this.imageUrl = "http://img.c-fol.cn/" + res.key;
      this.formData.logoId = res.key;
    },
    async beforeAvatarUpload(file) {
      // console.log("上传前");
      let { data } = await getUploadToken();
      // console.log(data);
      if (data == "" || data == null) {
        this.message.error("上传图片时获取Token失败！");
        return;
      }
      this.postData.token = data.token;
      this.postData.key = data.fid;
    },
  
  },
};
</script>

<style>

</style>